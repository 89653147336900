import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';
import Link from 'next/link';
import i18n from 'src/utils/translate';
import { useSelector } from 'src/store/store';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import { textLight } from 'styles-js/mixins/typography';

export default function HomeNav({ isNewsfeed }: { isNewsfeed?: boolean }) {
  const router = useRouter();
  const currentUser = useSelector(({ context }) => context?.currentUser);
  const percentageComplete = useSelector(({ currentUserProfile }) => currentUserProfile.basics.percentageComplete || 0);

  if (!currentUser) return null;
  const roundedPercent = Math.floor(percentageComplete);

  return (
    <Nav $isNewsfeed={isNewsfeed} aria-label="my feed navigation" data-testid="home-nav">
      <UserCard $isNewsfeed={isNewsfeed}>
        <img alt={currentUser.username + ' profile image'} src={currentUser.avatarImageUrl} />
        <User>
          <Name>{currentUser.username}</Name>
          {isNewsfeed && roundedPercent < 100 && (
            <ProfileCompletionPercentage>
              {i18n.t(`${roundedPercent}% complete`)}
            </ProfileCompletionPercentage>
          )}
          <Link
            legacyBehavior
            passHref
            href="/profile/edit"
          >
            <UpdateProfileLink
              onClick={() => {
                if (!isNewsfeed) return;
                trackFootfallEvent(Event.Clicked, { clickType: 'update-profile', clickMetadata: { section: Section.NewsFeedProfile } });
              }}
            >
              {i18n.t('Update profile')}
            </UpdateProfileLink>
          </Link>
        </User>
      </UserCard>
      <ul>
        <Item $isActive={'/newsfeed' === router.pathname}>
          <Link href="/newsfeed">
            <DesktopText>{i18n.t('News Feed')}</DesktopText>
            <MobileText>{i18n.t('News Feed')}</MobileText>
          </Link>
        </Item>
        <Item $isActive={'/explore' === router.pathname}>
          <Link href="/explore">
            <DesktopText>{i18n.t('Explore')}</DesktopText>
            <MobileText>{i18n.t('Explore')}</MobileText>
          </Link>
        </Item>
        <Item $isActive={'/followed' === router.pathname}>
          <Link href="/followed">
            <DesktopText>{i18n.t('Saved posts')}</DesktopText>
            <MobileText>{i18n.t('Saved')}</MobileText>
          </Link>
        </Item>
      </ul>
    </Nav>
  );
}

const Nav = styled.nav<{ $isNewsfeed?: boolean }>`
  border: ${({ theme }) => theme.greyLine};
  @media (${({ theme }) => theme.underScreenSmall}) {
    border: none;
    box-shadow: ${({ theme }) => theme.shadowLight};
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    @media (${({ theme }) => theme.underScreenSmall}) {
      display: flex;
      padding: 0 8px;
      border-top: ${({ theme }) => theme.greyLine};
      ${props => props.$isNewsfeed && 'border: none;'}
    }
  }
`;

const Item = styled.li<{ $isActive: boolean }>`
  border-top: ${({ theme }) => theme.greyLine};
  a {
    display: block;
    padding: 8px 10px;
    border-left: 4px solid transparent;
    color: ${({ theme }) => theme.colorBlack};
    font-size: ${({ theme }) => theme.fontSizeS};
    line-height: ${({ theme }) => theme.fontLineHeightSWide};
    &:hover, &:focus, &:active {
      border-color: ${({ theme }) => theme.colorGreyLight};
    }
    ${({ $isActive }) => $isActive && css`
      border-color: ${({ theme }) => theme.colorGreen};
      font-weight: ${({ theme }) => theme.fontWeightBold};
      :hover {
        border-color: ${({ theme }) => theme.colorGreen};
      }
    `}
  }
  @media (${({ theme }) => theme.underScreenSmall}) {
    border-top: none;
    a {
      border-left: none;
      border-bottom: 4px solid transparent;
      padding: 6px 0;
      margin: 0 8px;
    }
  }
`;

const DesktopText = styled.span`
  @media (${({ theme }) => theme.underScreenSmall}) {
    display: none;
  }
`;

const MobileText = styled.span`
  @media (${({ theme }) => theme.overScreenSmall}) {
    display: none;
  }
`;

const UserCard = styled.div<{ $isNewsfeed?: boolean }>`
  display: flex;
  padding: 15px 15px 30px;
  ${props => props.$isNewsfeed && 'padding-bottom: 15px;'}

  @media (${({ theme }) => theme.underScreenSmall}) {
    ${props => props.$isNewsfeed && 'display: none;'}
  }

  img {
    width: 62px;
    height: 62px;
    border-radius: 50%;
    margin-right: 10px;
  }
`;

const User = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;
`;

const Name = styled.div`
  font-size: ${({ theme }) => theme.fontSizeM};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  line-height: ${({ theme }) => theme.fontLineHeightM};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProfileCompletionPercentage = styled.span`
  ${textLight};
  margin: 10px 0;
`;

const UpdateProfileLink = styled.a<{ isNewsfeed?: boolean }>`
  ${props => props.isNewsfeed && 'display: block;'}
`;
